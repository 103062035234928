import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import { Link, graphql } from "gatsby";
import BasicTable from "components/basicTable";
import RaderChart from "components/raderChartLoadable";
import MakerRanking from "components/makerRankingLoadable";
import Socials from "components/socials";
import TOC from "components/toc";
import AccentBox from 'components/accentBox';
import SmallBox from 'components/smallBox';
import Lineup from 'components/lineup';
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "makers/nec"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    nec_wordcloud: file(relativePath: { eq: "pcmaker/nec_wordcloud.png"}) { ...normalImg },
    nec: file(relativePath: { eq: "pcmaker/nec.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`NECパソコンの評判 - 親しみが持てるデザインとシェアトップの圧倒的な安心感`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="nec_wordcloud" alt="NECの評判" fadeIn={false} mdxType="Image" />
    <p>{`NECは富士通、Dynabookと並ぶ日系大手パソコンメーカーの一つであり、日本でのシェアは約20%を安定して占めており、国内トップである。`}</p>
    <p>{`割高ではあるものの、親しみやすいデザインと幕の内弁当とも呼ばれる無難な製品を繰り出す抜群の安心感と初心者にも優しいサポート体制が売りである。`}</p>
    <p>{`ラインナップも豊富である。それでは特徴を述べていこう。尚、他メーカーと比較検討したい場合は`}<Link to="/pcmaker/" mdxType="Link">{`パソコンメーカーおすすめランキング`}</Link>{`も併せて参照して欲しい。`}</p>
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "NECパソコンのラインナップと特徴",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#NEC%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E3%83%A9%E3%82%A4%E3%83%B3%E3%83%8A%E3%83%83%E3%83%97%E3%81%A8%E7%89%B9%E5%BE%B4",
        "aria-label": "NECパソコンのラインナップと特徴 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`NECパソコンのラインナップと特徴`}</h2>
    <Lineup {...props} maker="nec" mdxType="Lineup" />
    <p>{`NECは大手電機メーカーの１社であり、パソコン事業は1979年から参入しており歴史が古い。`}<strong parentName="p"><em parentName="strong">{`LAVIE`}</em></strong>{`というブランドで発売されている。`}</p>
    <p>{`ラインナップとしてはゲーミングPCは扱っていないものの、`}<strong parentName="p"><em parentName="strong">{`売れ筋の15.6インチのスタンダードノートを始めとして幅広いモデルを提供`}</em></strong>{`する。`}</p>
    <p>{`NECは一貫して`}<strong parentName="p"><em parentName="strong">{`「安心・簡単・快適」をセールスポイントとして商品を企画・設計`}</em></strong>{`しており、デザインもスタイリッシュというよりは親しみが持てるデザインが多い。`}</p>
    <p>{`親しみは持てるがダサくはないという絶妙なラインを責めているのが印象である。`}</p>
    <div align="right">>> <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=885175200&vc_url=http%3A%2F%2Fnec-lavie.jp%2Fshop%2Findex.html%3Fsc_cid%3Djp%3Aaffiliate%3Atgniag" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=885175200" height="1" width="0" border="0" alt="" />ラインナップを見る</a></div>
    <h2 {...{
      "id": "NECパソコンの評判、総評と項目別評価",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#NEC%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E8%A9%95%E5%88%A4%E3%80%81%E7%B7%8F%E8%A9%95%E3%81%A8%E9%A0%85%E7%9B%AE%E5%88%A5%E8%A9%95%E4%BE%A1",
        "aria-label": "NECパソコンの評判、総評と項目別評価 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`NECパソコンの評判、総評と項目別評価`}</h2>
    <RaderChart maker='nec' className={'top-margin'} mdxType="RaderChart" />
    <p>{`NECのパソコンは`}<strong parentName="p"><em parentName="strong">{`パソコンに苦手意識のある人でも簡単に使える点が一番の魅力である`}</em></strong>{`。`}</p>
    <p>{`通常パソコンを購入すると、Windowsの標準ソフトのみがインストールされており、パソコンに慣れた人でないと何をしたら良いのかが分からない状態となる。`}</p>
    <p>{`しかし「動画配信サービスを楽しむ」、「子供のパソコン利用を助ける」など目的から必要な操作やソフトを教えてくれる`}<em parentName="p">{`LAVIEアプリナビ`}</em>{`や、分かりやすい取扱説明書、割安な追加料金で可能な出張レッスン、初期設定サービスなどにより、`}<strong parentName="p"><em parentName="strong">{`パソコンの苦手意識のある人でも難なく使いこなせるように設計されている`}</em></strong>{`。`}</p>
    <p>{`パソコンとしての売れ筋は15.6インチのスタンダードパソコンであるが、日系大手の強みであるハードウェア性能を活かした軽量・堅牢なモバイルノートが実は製品力が高い。`}</p>
    <AccentBox title="NECパソコンの項目別評価" mdxType="AccentBox">
  <li><span className="bold">コストパフォーマンス</span>・・・スペック当たりの価格は高い。</li>
  <li><span className="bold">サポート</span>・・・<a href="https://support.nec-lavie.jp/" target="_blank" rel="nofollow noopenner">サポートサイト</a>でチャット、LINE、予約電話相談が無料の他、上述のようなアプリでのサポートも強く、一気通貫して高い。</li>
  <li><span className="bold">デザイン</span>・・・親しみのあるデザイン。割とカッコよいモデルもちらほら。</li>
  <li><span className="bold">人気</span>・・・当サイトでの人気。それなりに売れる。しかし購入層がパソコン一台目という人が多く、ネットで購入する人が少ないからかシェア程の売り上げはない。</li>
  <li><span className="bold">安定性</span>・・・古くからパソコン事業を営みシェアも高く安定している。</li>
    </AccentBox>
    <h2 {...{
      "id": "実際に売れているモデルはこれ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%AE%9F%E9%9A%9B%E3%81%AB%E5%A3%B2%E3%82%8C%E3%81%A6%E3%81%84%E3%82%8B%E3%83%A2%E3%83%87%E3%83%AB%E3%81%AF%E3%81%93%E3%82%8C",
        "aria-label": "実際に売れているモデルはこれ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`実際に売れているモデルはこれ`}</h2>
    <p>{`本サイトを経由して実際によく売れているモデルを紹介する。当サイトはパソコンにそれほど詳しくはないが、インターネットで買い物ができるなど標準的なITリテラシーを持った人をメインターゲットとしており、比較して堅実に買い物をしている人が多いと思われる。そのような層で人気が高いパソコンは下記の２つである。`}</p>
    <h3 {...{
      "id": "LAVIE-N15---日本で最もシェアが高い万能パソコン",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#LAVIE-N15---%E6%97%A5%E6%9C%AC%E3%81%A7%E6%9C%80%E3%82%82%E3%82%B7%E3%82%A7%E3%82%A2%E3%81%8C%E9%AB%98%E3%81%84%E4%B8%87%E8%83%BD%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3",
        "aria-label": "LAVIE N15   日本で最もシェアが高い万能パソコン permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`LAVIE N15 - 日本で最もシェアが高い万能パソコン`}</h3>
    <p>{`全国の家電量販店やECショップでPOSデータを集計している`}<strong parentName="p"><em parentName="strong">{`BCNランキングで常にトップを取っているパソコン`}</em></strong>{`である。`}</p>
    <p>{`特に何かが際立っているということもない15.6インチのスタンダードノートパソコンであるが、HDMIやType A端子など豊富なインターフェースやDVD/BDドライブ(BDドライブは機種による)が付属し、外付け機器に煩わされることもない。`}</p>
    <p>{`また`}<strong parentName="p"><em parentName="strong">{`シェアが高いことにより情報が豊富で、不具合があった場合にはインターネットですぐに調べることができる点も大きなメリット`}</em></strong>{`である。デザインも良く、最初に購入するパソコンとしてはベストな選択肢の一つと言えるだろう。`}</p>
    <SmallBox type="word accent" text="製品ページへ" mdxType="SmallBox">
      <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=885175200&vc_url=https%3A%2F%2Fwww.nec-lavie.jp%2Fproducts%2Fnotebook%2Flavie%2Fn15%2F%3Fsc_cid%3Djp%3Aaffiliate%3Atgniag" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=885175200" height="1" width="0" border="0" />LAVIE N15</a>
    </SmallBox>
    <h3 {...{
      "id": "LAVIE-N12---小さな鞄にも入り、狭いカフェでも重宝する絶妙サイズ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#LAVIE-N12---%E5%B0%8F%E3%81%95%E3%81%AA%E9%9E%84%E3%81%AB%E3%82%82%E5%85%A5%E3%82%8A%E3%80%81%E7%8B%AD%E3%81%84%E3%82%AB%E3%83%95%E3%82%A7%E3%81%A7%E3%82%82%E9%87%8D%E5%AE%9D%E3%81%99%E3%82%8B%E7%B5%B6%E5%A6%99%E3%82%B5%E3%82%A4%E3%82%BA",
        "aria-label": "LAVIE N12   小さな鞄にも入り、狭いカフェでも重宝する絶妙サイズ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`LAVIE N12 - 小さな鞄にも入り、狭いカフェでも重宝する絶妙サイズ`}</h3>
    <p>{`ラインナップが豊富なNECは12.5インチという、外出先での使用に適したパソコンを発売している。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`大学生やカフェや自習室に気軽に持ち出して使いたい人をターゲット`}</em></strong>{`としており、映像編集などクリエイティブな作業はあまりしないけれどもレポートを書いたり、インターネットを閲覧したりする用途には適している。`}</p>
    <p>{`そこまで売れている訳ではないが、他社にはあまりないモデルとして注目したい。`}</p>
    <SmallBox type="word accent" text="製品ページへ" mdxType="SmallBox">
      <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=885175200&vc_url=https%3A%2F%2Fwww.nec-lavie.jp%2Fproducts%2Fnotebook%2Flavie%2Fn12%2F%3Fsc_cid%3Djp%3Aaffiliate%3Atgniag" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=885175200" height="1" width="0" border="0" />LAVIE N12</a>
    </SmallBox>
    <h2 {...{
      "id": "NECパソコンの３大悪評",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#NEC%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%EF%BC%93%E5%A4%A7%E6%82%AA%E8%A9%95",
        "aria-label": "NECパソコンの３大悪評 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`NECパソコンの３大悪評`}</h2>
    <p>{`悪評は購入時の期待値と実際の乖離から生じるものである。よって`}<strong parentName="p"><em parentName="strong">{`購入前に齟齬が出そうな点をあらかじめ抑えておくことが重要`}</em></strong>{`となる。ここではネットでの評判を抽出し、その妥当性についてコメントしていく。`}</p>
    <h3 {...{
      "id": "価格が高い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E4%BE%A1%E6%A0%BC%E3%81%8C%E9%AB%98%E3%81%84",
        "aria-label": "価格が高い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`価格が高い`}</h3>
    <p>{`スペックに対して価格が高いということだが、全くその通りである。Celeronと呼ばれる動作がもっさりとした最底辺のCPUを搭載したパソコンに対して10万円の価格を提示するのは日系大手メーカーぐらいである。`}</p>
    <p>{`ただNECは前述のように`}<strong parentName="p"><em parentName="strong">{`安心・簡単・快適`}</em></strong>{`であることに付加価値を追求しているので、そこに対して価値を見いだせる人でないと一部の競争力のあるモバイルPCを除いては購入には至らないだろう。`}</p>
    <p>{`安くて速いパソコンを望むならば、DellやLenovoといったメーカーを選択すると良い。`}<strong parentName="p"><em parentName="strong">{`そもそもターゲット層が異なる`}</em></strong>{`という事を頭に入れよう。`}</p>
    <h3 {...{
      "id": "初期構成のスペック低すぎる問題",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%88%9D%E6%9C%9F%E6%A7%8B%E6%88%90%E3%81%AE%E3%82%B9%E3%83%9A%E3%83%83%E3%82%AF%E4%BD%8E%E3%81%99%E3%81%8E%E3%82%8B%E5%95%8F%E9%A1%8C",
        "aria-label": "初期構成のスペック低すぎる問題 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`初期構成のスペック低すぎる問題`}</h3>
    <p>{`富士通など他の大手日系メーカーにも当てはまることだが、メモリ4GBなどパソコンの初期構成のスペックが極めて低くなっているところは気にかかる点である。`}</p>
    <p>{`ターゲット層はあまりパソコンに詳しく無い人も多いのにも関わらず遅くて使い物にならないデフォルトパソコン構成で販売するのは如何なものか。`}</p>
    <p>{`マーケティング上仕方のない判断なのかもしれないが、本サイトなどで`}<strong parentName="p"><em parentName="strong">{`最低限のスペックに関する知識を得てから購入することが後悔を防ぐ唯一の手段`}</em></strong>{`である。`}</p>
    <h3 {...{
      "id": "サポートが最悪",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E3%81%8C%E6%9C%80%E6%82%AA",
        "aria-label": "サポートが最悪 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`サポートが最悪`}</h3>
    <p>{`NECはサポートが最悪というネットへの書き込みは少なからずある。ただパソコンの難しい質問に答えられなかった、オペレータの対応が悪かったなどを腹いせに書き込んでいるだけである。`}</p>
    <p>{`実際のところ、オペレータは基本どこも別会社に依頼してアルバイトを雇っているなどで、一定の割合で対応が悪い人はいるであろうし、パソコンの不具合もオペレータが開発と共有できていない不具合は答えようがないケースも多い。`}</p>
    <p>{`NECのサポートが最悪ならばどこも最悪であるのであまりネットの書き込みを安直に信じない方が良いだろう。`}</p>
    <h2 {...{
      "id": "NECパソコンの評判まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#NEC%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%81%AE%E8%A9%95%E5%88%A4%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "NECパソコンの評判まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`NECパソコンの評判まとめ`}</h2>
    <p>{`以上、パソコンを使いこなすことに`}<strong parentName="p"><em parentName="strong">{`あまり自信のない人が、徐々に使えるようになるのに適したパソコンがNEC`}</em></strong>{`である。商品のラインナップや購入を検討している場合は下記から公式の直販サイトへ飛ぶことができる。`}</p>
    <SmallBox type="word" text="公式サイトへ" mdxType="SmallBox">
      <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=885175200&vc_url=http%3A%2F%2Fnec-lavie.jp%2Fshop%2Findex.html%3Fsc_cid%3Djp%3Aaffiliate%3Atgniag" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=885175200" height="1" width="0" border="0" alt="" />NEC</a>
    </SmallBox>
    <h2 {...{
      "id": "他社との比較",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E4%BB%96%E7%A4%BE%E3%81%A8%E3%81%AE%E6%AF%94%E8%BC%83",
        "aria-label": "他社との比較 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`他社との比較`}</h2>
    <p>{`パソコンの種類や用途により得意なメーカーは分かれるため、当サイトでは選択肢に応じたランキングを制作している。`}</p>
    <p>{`デフォルトは当サイトの人気順であるが、探している種類やこだわりがあれば選択し、自分にあったパソコンを探してほしい。`}</p>
    <p>{`NECパソコンと似ているパソコンとしては富士通やDynabookを同時に確認したい。リンクをクリックすると直販サイトへ移動します。`}</p>
    <MakerRanking highlight='nec' mdxType="MakerRanking" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      